.photo {
  /*height: 100%;*/
  /*-webkit-transform: translate(-50%, -50%);*/
  /*-moz-transform: translate(-50%, -50%);*/
  /*-ms-transform: translate(-50%, -50%);*/
  /*-o-transform: translate(-50%, -50%);*/
  /*transform: translate(-50%, -50%);*/
  /*left: 50%;*/
  /*top: 50%;*/
  /*position: relative;*/
  background-color: #FFF;
  object-fit: contain;

  /*-webkit-box-shadow: 0 10px 6px -6px #777;*/
  /*-moz-box-shadow: 0 10px 6px -6px #777;*/
  /*box-shadow: 0 20px 12px -12px #777;*/
}

