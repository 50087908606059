.rt-resizable-header-content {
  /*height: 17px;*/
  color: rgba(66,51,19,0.4);
  font-family: "Helvetica Neue";
  font-size: 12px;
  font-weight: bold;
  line-height: 17px;
}

.ReactTable .rt-thead .rt-th, .ReactTable .rt-thead .rt-td {
  border-right: none;
}

.ReactTable .rt-tbody {
  overflow-y: scroll; /* has to be scroll, not auto */
  -webkit-overflow-scrolling: touch;
}

.ReactTable .rt-tbody .rt-td {
  border-right: none;
  color: rgba(66,51,19,0.8);
  font-family: "Helvetica Neue";
  font-size: 16px;
  /*line-height: 35px;*/
}

.ReactTable .rt-thead .rt-tr {
  white-space: normal;
  text-align: left;
  margin-top: 12px;
  /*text-transform: uppercase;*/
}

.ReactTable .rt-thead.-header {
  border-bottom: 1px solid #BFBAAE !important;
  -webkit-box-shadow: none;
  box-shadow: none;
  height: 60px;
}

.ReactTable .rt-tbody .rt-tr-group {
  border-bottom: 1px solid #E8E7E3 !important;
}

.ReactTable .rt-head:focus, .rt-th:focus {
  outline: none;
}

.ReactTable .rt-thead .rt-th.-sort-asc, .ReactTable .rt-thead .rt-td.-sort-asc {
  box-shadow: none !important;
}

.ReactTable .rt-thead .rt-th.-sort-desc, .ReactTable .rt-thead .rt-td.-sort-desc {
  box-shadow: none !important;
}

.ReactTable.-highlight .rt-tbody .rt-tr:not(.-padRow):hover {
  background-color: rgba(194,227,255,0.6);
}

.ReactTable .rt-tr:active {
  background-color: #C2E3FF;
}